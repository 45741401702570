import { MEDIA_TYPE } from '../media-type';
import * as i0 from "@angular/core";
var TitleUrlsService = /** @class */ (function () {
    function TitleUrlsService() {
    }
    TitleUrlsService.prototype.mediaItem = function (item) {
        if (item.type === MEDIA_TYPE.TITLE) {
            return ['/titles', item.id];
        }
        else if (item.type === MEDIA_TYPE.PERSON) {
            return ['/people', item.id];
        }
        else if (item.type === MEDIA_TYPE.EPISODE) {
            var ep = item;
            return ['/titles', ep.title_id, 'season', ep.season_number, 'episode', ep.episode_number];
        }
        else if (item.type === MEDIA_TYPE.NEWS) {
            return ['/news', item.id];
        }
    };
    TitleUrlsService.prototype.season = function (series, seasonNumber) {
        return ['/titles', series.id, 'season', seasonNumber];
    };
    TitleUrlsService.prototype.episode = function (series, season, episode) {
        return this.season(series, season).concat(['episode', episode]);
    };
    TitleUrlsService.ngInjectableDef = i0.defineInjectable({ factory: function TitleUrlsService_Factory() { return new TitleUrlsService(); }, token: TitleUrlsService, providedIn: "root" });
    return TitleUrlsService;
}());
export { TitleUrlsService };
