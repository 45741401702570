import {Injectable} from '@angular/core';
import {Title} from '../../models/title';
import {MEDIA_TYPE} from '../media-type';
import {Person} from '../../models/person';
import {Episode} from '../../models/episode';
import {NewsArticle} from '../../models/news-article';

@Injectable({
    providedIn: 'root'
})
export class TitleUrlsService {
    constructor() {}

    public mediaItem(item: Title | Person | Episode | NewsArticle): any[] {
        if (item.type === MEDIA_TYPE.TITLE) {
            return ['/titles', item.id];
        } else if (item.type === MEDIA_TYPE.PERSON) {
            return ['/people', item.id];
        } else if (item.type === MEDIA_TYPE.EPISODE) {
            const ep = item as Episode;
            return ['/titles', ep.title_id, 'season', ep.season_number, 'episode', ep.episode_number];
        } else if (item.type === MEDIA_TYPE.NEWS) {
            return ['/news', item.id];
        }
    }

    public season(series: Title, seasonNumber: number) {
        return ['/titles', series.id, 'season', seasonNumber];
    }

    public episode(series: Title, season: number, episode: number) {
        return this.season(series, season).concat(['episode', episode]);
    }
}
