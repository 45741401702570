/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../common/core/translations/translate.directive";
import * as i10 from "../../../../common/core/translations/translations.service";
import * as i11 from "../../../../common/core/config/settings.service";
import * as i12 from "@angular/common";
import * as i13 from "@angular/router";
import * as i14 from "../../shared/more-less-text.directive";
import * as i15 from "../../titles/components/genre-widget/genre-widget.component.ngfactory";
import * as i16 from "../../titles/components/genre-widget/genre-widget.component";
import * as i17 from "./slider.directive";
import * as i18 from "@ngxs/store";
import * as i19 from "./slider.component";
import * as i20 from "../../titles/title-urls.service";
import * as i21 from "../../../../common/core/ui/breakpoints.service";
var styles_SliderComponent = [i0.styles];
var RenderType_SliderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SliderComponent, data: {} });
export { RenderType_SliderComponent as RenderType_SliderComponent };
function View_SliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "rating"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "star"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(3, null, [" ", " / 10 "]))], function (_ck, _v) { var currVal_2 = "star"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.rating; _ck(_v, 3, 0, currVal_3); }); }
function View_SliderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "play-trailer-button"], ["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playVideo(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "play-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i9.TranslateDirective, [i1.ElementRef, i10.Translations, i11.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Play Trailer"]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); var currVal_5 = "play-arrow"; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_SliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "slide"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "slide-cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "slide-content container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_3)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "title"], ["draggable", "false"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i12.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "description"]], null, null, null, null, null)), i1.ɵdid(9, 540672, null, 0, i14.MoreLessTextDirective, [i1.ElementRef, i10.Translations], { limit: [0, "limit"], textContent: [1, "textContent"] }, null), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "genre-widget", [], null, null, null, i15.View_GenreWidgetComponent_0, i15.RenderType_GenreWidgetComponent)), i1.ɵdid(12, 49152, null, 0, i16.GenreWidgetComponent, [], { genres: [0, "genres"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_4)), i1.ɵdid(14, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.rating; _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 6, 0, currVal_4); var currVal_6 = (i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.breakpoints.isMobile$)) ? 200 : 350); var currVal_7 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_8 = _v.context.$implicit.genres; _ck(_v, 12, 0, currVal_8); var currVal_9 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.videos == null) ? null : _v.context.$implicit.videos.length)); _ck(_v, 14, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (("url(" + _v.context.$implicit.backdrop) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).target; var currVal_3 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_5); }); }
function View_SliderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "control"]], [[2, "active", null]], [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.changeSlide(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "control-inner"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.activeSlide$))); _ck(_v, 0, 0, currVal_0); }); }
function View_SliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "slider-track"], ["slider", ""]], null, [[null, "swipeleft"], [null, "swiperight"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("swipeleft" === en)) {
        var pd_0 = (_co.changeSlide("previous") !== false);
        ad = (pd_0 && ad);
    } if (("swiperight" === en)) {
        var pd_1 = (_co.changeSlide("next") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 4341760, null, 0, i17.SliderDirective, [i1.ElementRef, i18.Store], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_2)), i1.ɵdid(4, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "slider-controls"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_5)), i1.ɵdid(7, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterTitles(_v.context.ngIf.items); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.ngIf.items; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SliderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SliderComponent_1)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.sliderList$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slider", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 1).suspendAutoSlide = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 1).suspendAutoSlide = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_SliderComponent_0, RenderType_SliderComponent)), i1.ɵdid(1, 114688, null, 0, i19.SliderComponent, [i18.Store, i20.TitleUrlsService, i21.BreakpointsService, i11.Settings, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderComponentNgFactory = i1.ɵccf("slider", i19.SliderComponent, View_SliderComponent_Host_0, {}, {}, ["*"]);
export { SliderComponentNgFactory as SliderComponentNgFactory };
